import React, { useEffect } from 'react'

import { FaLink, FaLinkSlash } from 'react-icons/fa6'
import { useLocation } from 'react-router-dom'

import { requestService } from '../../services/requestServiceNew'
import { useQuickBook } from '../../store/useQuickBook'

export const QuickBookStatus = () => {
  const { status, setStatus } = useQuickBook()
  const location = useLocation()

  useEffect(() => {
    const getStatus = async () => {
      try {
        const response = await requestService({ path: `quickbook/status` })
        if (response.ok) {
          const { status } = await response.json()
          setStatus(status)
        }
      } catch (error) {
        console.error('Failed to fetch jobs:', error)
      }
    }
    getStatus()
  }, [setStatus])

  const handleAuthQuickbook = async () => {
    try {
      const current_url = `${window.location.origin}${location.pathname}${location.search}${location.hash}`
      const response = await requestService({
        path: `quickbook/auth`,
        method: 'POST',
        body: JSON.stringify({ current_url }),
      })
      if (response.ok) {
        const { url } = await response.json()
        window.location.href = url
      }
    } catch (error) {
      console.error('Failed to fetch jobs:', error)
    }
  }

  return (
    <div className="flex flex-row items-center justify-center gap-3">
      <div className="text-lg">QuickBook</div>
      {status === 'Active' ? (
        <FaLink className="h-5 w-5 text-green-400" />
      ) : (
        <FaLinkSlash className="h-5 w-5 cursor-pointer text-red-400" onClick={handleAuthQuickbook} />
      )}
    </div>
  )
}
