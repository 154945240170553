export type INotificationPreference = 'notification_email' | 'notification_sms'

export const notifications_preferences: INotificationPreference[] = ['notification_email', 'notification_sms']

export const job_preferences = [
  'Harvester',
  'Budtender',
  'Trimmer',
  'Packager',
  'Gardener',
  'Cultivator',
  'Extractor',
  'Front desk',
  'Greeter',
  'Id checker',
  'Inventory',
  'Data entry',
  'Event staff',
  'Promo representative',
  'Cleaning',
  'Joint roller',
  'Grow tech',
  'Clone tech',
  'Sign spinner',
]

export const facilityContactRoles = [
  { label: 'Owner', value: 'Owner' },
  { label: 'AP', value: 'AP' },
  { label: 'Onsite', value: 'Onsite' },
  { label: 'Security', value: 'Security' },
  { label: 'Manager', value: 'Manager' },
  { label: 'Other', value: 'Other' },
]

export const services = ['Trimming', 'Harvesting', 'Packaging', 'Budtending', 'Gardening', 'General Labor', 'Other']

export const countries = [
  { label: 'United States', value: 'US' },
  { label: 'Canada', value: 'CA' },
  { label: 'Mexico', value: 'MX' },
]

export const states = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
]

export const jobTitlesOptions = [
  { title: 'Harvester', value: 'Harvester' },
  { title: 'Budtender', value: 'Budtender' },
  { title: 'Trimmer', value: 'Trimmer' },
  { title: 'Packager', value: 'Packager' },
  { title: 'Extractor', value: 'Extractor' },
  { title: 'Front desk', value: 'Front desk' },
  { title: 'Greeter', value: 'Greeter' },
  { title: 'Id checker', value: 'Id checker' },
  { title: 'Inventory', value: 'Inventory' },
  { title: 'Data entry', value: 'Data entry' },
  { title: 'Event staff', value: 'Event staff' },
  { title: 'Promo representative', value: 'Promo representative' },
  { title: 'Cleaning', value: 'Cleaning' },
  { title: 'Joint roller', value: 'Joint roller' },
  { title: 'Grow tech', value: 'Grow tech' },
  { title: 'Clone tech', value: 'Clone tech' },
  { title: 'Sign spinner', value: 'Sign spinner' },
]

export const lunchTimeOptions = [
  { label: '30 minutes', value: 30 },
  { label: '45 minutes', value: 45 },
  { label: '60 minutes', value: 60 },
]

export const jobTipsOptions = [
  { label: 'Change Required Upon Entry', value: 'Change Required Upon Entry' },
  { label: 'Lunch Included', value: 'Lunch Included' },
  { label: 'Lunch Room Available', value: 'Lunch Room Available' },
  { label: 'Lunch Will Be Off-Premise', value: 'Lunch Will Be Off-Premise' },
  { label: 'Pack a Lunch', value: 'Pack a Lunch' },
  { label: 'Parking on Street', value: 'Parking on Street' },
  { label: 'Parking Onsite', value: 'Parking Onsite' },
  { label: 'Required Identification', value: 'Required Identification' },
  { label: 'Special Equipment', value: 'Special Equipment' },
  { label: 'No gas stations nearby', value: 'No gas stations nearby' },
  { label: 'Water is provided', value: 'Water is provided' },
  { label: 'Outdoor sun exposure', value: 'Outdoor sun exposure' },
  { label: 'Must be able to lift 50 lbs', value: 'Must be able to lift 50 lbs' },
  { label: 'Steel toe shoes', value: 'Steel toe shoes' },
  { label: 'Lab coat Provided', value: 'Lab coat Provided' },
  { label: 'Head / Beard net required', value: 'Head / Beard net required' },
]
