import { create } from 'zustand'

interface Customer {
  value: string
  label: string
}

interface State {
  status: 'Active' | 'Inactive'
  setStatus: (status: 'Active' | 'Inactive') => void
  customers: Customer[]
  setCustomers: (customers: Customer[]) => void
}

export const useQuickBook = create<State>(set => ({
  status: 'Inactive',
  setStatus: status => set({ status }),
  customers: [],
  setCustomers: customers => set({ customers }),
}))
